import { Message } from "ai";

import { useCallback, useEffect, useState } from "react";

import { PostgrestError } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";

import { useAccount } from "contexts/AccountContext";

import { supabase } from "./supabaseClient";

export type Chat = {
  id: string;
  userId: string;
  title: string;
  createdAt: string;
  deletedAt: string | null;
};

export type ChatDb = {
  id: string;
  user_id: string;
  title: string;
  created_at: string;
  deleted_at: string | null;
};

export type ChatRole = "user" | "system" | "assistant" | "tool";

export type GenieMessage = Message & { rating?: number };

type ContextType = "location" | "experiences" | "resume";

export type UserChatContext = {
  contextType: ContextType;
  documentFilename?: string;
  userId: string;
  description: string;
  text: string;
  updatedAt: string;
};

export type ChatSource = "chat" | "genie";

export const rateMessage = async ({
  messageId,
  rating,
}: {
  messageId: string;
  rating: number;
}) => {
  const { error } = await supabase
    .from("genie_message")
    .update({ rating })
    .eq("id", messageId);

  return { error: error?.message };
};

export const getGenieChatInfo = async (
  id: string
): Promise<{ chat: Chat; chatMessages: GenieMessage[] } | undefined> => {
  const [chatResponse, messageResponse] = await Promise.all([
    supabase
      .from("chat")
      .select("*")
      .eq("id", id)
      .eq("source", "genie")
      .maybeSingle(),
    supabase
      .from("genie_message")
      .select("*")
      .eq("chat_id", id)
      .order("message_order", { ascending: true }),
  ]);

  if (chatResponse.error) {
    logger(chatResponse.error.message, "error");
    throw new Error(chatResponse.error.message);
  }
  if (messageResponse.error) {
    logger(messageResponse.error.message, "error");
    throw new Error(messageResponse.error.message);
  }

  if (!chatResponse.data) {
    // Chat does not exist
    return undefined;
  }

  const chat = formatDbChat(chatResponse.data as ChatDb);
  const chatMessages = messageResponse.data.map((dbMessage) => ({
    id: dbMessage.id,
    role: dbMessage.role,
    content: dbMessage.content,
    toolInvocations: dbMessage.tool_invocations,
    createdAt: dbMessage.created_at,
    rating: dbMessage.rating,
  }));

  return { chat, chatMessages };
};

export const formatDbChat = (data: ChatDb): Chat => ({
  id: data.id,
  userId: data.user_id,
  createdAt: data.created_at,
  title: data.title,
  deletedAt: data.deleted_at,
});

const findChatHistory = async ({
  pageNumber,
  pageSize,
  userId,
  source,
}: {
  pageNumber: number;
  pageSize: number;
  userId: string;
  source: ChatSource;
}): Promise<Chat[]> => {
  const { data, error } = await supabase
    .from("chat")
    .select("*")
    .eq("user_id", userId)
    .eq("source", source)
    .filter("deleted_at", "is", "null")
    .range(
      (pageNumber - 1) * pageSize,
      (pageNumber - 1) * pageSize + pageSize - 1
    )
    .order("created_at", { ascending: false });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return (data as ChatDb[]).map(formatDbChat);
};

export const deleteChat = async (
  chatId: string
): Promise<{ error: PostgrestError | null }> => {
  const { error } = await supabase
    .from("chat")
    .update({ deleted_at: "now()" })
    .eq("id", chatId);

  return { error };
};

export const useChatHistory = () => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [chats, setChats] = useState<Chat[]>([]);
  const page_size = 5;
  const { accountProfile } = useAccount(true);
  const loadMoreChats = useCallback(async () => {
    setLoadingMore(false);
    const data = await findChatHistory({
      pageNumber: pageNumber + 1,
      pageSize: page_size,
      userId: accountProfile.id,
      source: "genie",
    });
    setPageNumber(pageNumber + 1);
    setChats((prevState) => [...prevState, ...data]);
    setLoadingMore(true);
    if (data.length < page_size) {
      setIsFinalPage(true);
      return;
    }
    setIsFinalPage(false);
  }, [pageNumber, accountProfile.id]);

  useEffect(() => {
    if (pageNumber > 0) return;
    const loadMore = async () => {
      await loadMoreChats();
      setIsLoaded(true);
    };
    loadMore();
  }, [loadMoreChats, pageNumber]);

  const deleteChatFromHistory = async (
    chatId: string
  ): Promise<{ error: boolean }> => {
    const deletedChat = chats.find((chat) => chat.id === chatId);
    setChats((prev) => prev.filter((chat) => chat.id != chatId));
    const { error } = await deleteChat(chatId);
    if (error && deletedChat) setChats((prev) => [...prev, deletedChat]);
    return { error: !!error };
  };

  return {
    chats,
    isFinalPage,
    loadMoreChats,
    loadingMore,
    isLoaded,
    deleteChatFromHistory,
  };
};

export const createGenieMessages = async ({
  chatId,
  messages,
}: {
  chatId: string;
  messages: Message[];
}) => {
  const { error } = await supabase.from("genie_message").upsert(
    messages.map((message) => ({
      chat_id: chatId,
      id: message.id,
      role: message.role,
      content: message.content,
      tool_invocations: message.toolInvocations,
      created_at: message.createdAt,
    })),
    { onConflict: "id", ignoreDuplicates: true } // this will only insert the new messages
  );

  if (error) {
    console.error("Error creating a chat message", error);
    throw new Error(error.message);
  }
};

export const getGenieChatTitle = async (chatId: string) => {
  const { data, error } = await supabase
    .from("chat")
    .select("title")
    .eq("id", chatId)
    .single();

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data?.title;
};
