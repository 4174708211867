import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const AiNotetakerIcon = ({
  width = 24,
  height = 24,
  stroke = uiGray[90],
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 11.5V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H10.5"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4333 2.64102C18.2848 2.25483 17.9138 2 17.5 2C17.0862 2 16.7152 2.25483 16.5667 2.64102L16.0637 3.94879C16.0512 3.98133 16.0408 4.0082 16.0317 4.03167C16.0082 4.04081 15.9813 4.05115 15.9488 4.06367L14.641 4.56665C14.2548 4.71519 14 5.08623 14 5.5C14 5.91377 14.2548 6.28481 14.641 6.43335L15.9488 6.93633L16.2782 6.07979L15.9488 6.93634C15.9813 6.94885 16.0082 6.95919 16.0317 6.96833C16.0408 6.9918 16.0512 7.01867 16.0637 7.05121L16.5667 8.35898C16.7152 8.74517 17.0862 9 17.5 9C17.9138 9 18.2848 8.74517 18.4333 8.35898L18.9363 7.05121C18.9488 7.01867 18.9592 6.9918 18.9683 6.96833C18.9918 6.95919 19.0187 6.94885 19.0512 6.93633L20.359 6.43335C20.7452 6.28481 21 5.91377 21 5.5C21 5.08623 20.7452 4.71519 20.359 4.56665L19.0512 4.06367C19.0187 4.05115 18.9918 4.04081 18.9683 4.03167C18.9592 4.0082 18.9488 3.98133 18.9363 3.94879L18.4333 2.64102ZM18.1636 4.65983L18.7748 4.22521L18.1636 4.65983Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 13.25V14.25"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M10.5 12.75V14.75"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13.5 10.75V16.75"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16.5 12.25V15.25"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
